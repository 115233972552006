exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-du-an-js": () => import("./../../../src/pages/du-an.js" /* webpackChunkName: "component---src-pages-du-an-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lien-he-js": () => import("./../../../src/pages/lien-he.js" /* webpackChunkName: "component---src-pages-lien-he-js" */),
  "component---src-pages-tin-tuc-js": () => import("./../../../src/pages/tin-tuc.js" /* webpackChunkName: "component---src-pages-tin-tuc-js" */),
  "component---src-pages-tuyen-dung-js": () => import("./../../../src/pages/tuyen-dung.js" /* webpackChunkName: "component---src-pages-tuyen-dung-js" */),
  "component---src-pages-ve-chung-toi-js": () => import("./../../../src/pages/ve-chung-toi.js" /* webpackChunkName: "component---src-pages-ve-chung-toi-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-project-post-js": () => import("./../../../src/templates/project-post.js" /* webpackChunkName: "component---src-templates-project-post-js" */)
}

